const sharedUserFields = `
_id
createdAt
dateOfBirth
email
password
emailVerified
firstName
friendlyId
lastName
middleName
prefix
roles
suffix
type
updatedAt
address {
  address1
  address2
  city
  country
  state
  zip
}
meta {
  active
  autoGenerated
  dateCreated
  emailVerificationToken
  emailVerificationTokenExpiration
  lastActivity
  lastUpdated
  notes
  status
  testData
  sso
  source
}
userSettings {
  preferredContactMethod
}
contactInfo {
  businessPhone
  mobilePhone
  homePhone
  cellPhone
}
`;

const advisorUserFields = `
crd
firmCrd
firmName
userInvites {
  _id
  createdAt
  expectedEmail
  expectedFirstName
  expectedLastName
  inviteExpirationDate
  inviteStatus
  inviteType
}
advisedProducts {
  _id
  associationType
  product {
    ... on DafProduct {
      _id
      name
      productType
      totals {
        totalGranted
        totalContributed
        ytdContributed
        ytdGranted
      }
    }
  }
}
advisedUsers {
  _id
  firstName
  lastName
  totals {
    date
    portfolioBalance
    cash
    stock
    crypto
    alternativeAssets
    other
  }
  documents {
    _id
    createdAt
    fileName
    filePath
    fileType
    s3Key
    updatedAt
  }
}
entities {
  entity {
    ... on FirmEntity {
      _id
      entityName
    }
  }
}
`;

export { sharedUserFields, advisorUserFields };

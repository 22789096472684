import { gql } from "@apollo/client";

const GET_PLAID_ACCOUNTS_INFO = gql`
  query PlaidAccountsInfo($userId: ID!) {
    plaidAccountsInfo(userId: $userId) {
      ... on plaidAccountsInfoResponse {
        access_token
        account_id
        institution_name
        account_name
        account_balance
        account_mask
      }
    }
  }
`;

const GET_ALL_TRANSFER_ACTIVITY = gql`
  query GetAllTransferActivity {
    getAllTransferActivity {
      transfer_id
      donorId
      name
      email
      status
      type
      amount
      created
      guarantee_decision
      guarantee_decision_rationale
      failure_reason
      cancellable
      sweep_status
    }
  }
`;

const GET_ALL_SWEEPS = gql`
  query GetAllSweeps {
    getAllSweeps {
      sweep_id
      status
      amount
      created
      settled
    }
  }
`;

const GET_PLAID_LEDGER_INFO = gql`
  query GetPlaidLedgerInfo {
    getPlaidLedgerInfo {
      available_balance
      pending_balance
      name
    }
  }
`;

export {
  GET_PLAID_ACCOUNTS_INFO,
  GET_ALL_TRANSFER_ACTIVITY,
  GET_PLAID_LEDGER_INFO,
  GET_ALL_SWEEPS,
};
